const EVENT_NAMES = {
  pageView: "PageView",
};

const {
  ANALYTICS_TRACKER_URL,
  ANALYTICS_TRACKER_API_TOKEN,
  ANALYTICS_TRACKER_LOG_IN_CONSOLE,
} = window;

const PLATFORM = "web";
const APP_NAME = "Circle";

const trackEvent = async (
  eventName: string,
  properties: Record<string, unknown>,
) => {
  const url = ANALYTICS_TRACKER_URL;
  const token = ANALYTICS_TRACKER_API_TOKEN;
  const defaultProperties = {
    platform: PLATFORM,
    app_name: APP_NAME,
    triggered_at: new Date().toISOString(),
    ahoy_visitor: window.ANALYTICS_TRACKER_VISITOR,
  };

  const data = {
    event_name: eventName,
    properties: { ...defaultProperties, ...properties },
  };

  let body;
  try {
    body = JSON.stringify(data);
  } catch (error) {
    console.error("Error while converting data to JSON", error);
    return;
  }

  if (url && token) {
    await fetch(url, {
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: body,
    });
    return;
  }
  if (ANALYTICS_TRACKER_LOG_IN_CONSOLE === "true") {
    // eslint-disable-next-line no-console -- Log info to console. Needed for testing
    console.info(data);
  }
};

const trackPageView = (properties: Record<string, unknown>) =>
  trackEvent(EVENT_NAMES.pageView, properties);

export const analyticsTrackerApi = {
  trackPageView,
};
