import { absolutePath } from "./absolutePath";

export const paywallCheckout = {
  show: ({ paywallCheckoutPath }) =>
    absolutePath(`paywalls/checkout/${paywallCheckoutPath}`),
  preview: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/preview", params),
  prepare: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/prepare", params),
  create: ({ params = {} } = {}) => absolutePath("paywalls/checkout/", params),
  confirm: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/confirm", params),
  cleanUp: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/clean_up", params),
};
